import React, { Component } from 'react'

export default class SendMail extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="sendmail">
          <div className="colorlib-narrow-content">
            <div className="text-center">
              <p>Tutti i diritti riservati © 2022 Alessandro Tamburini</p>
            </div>
            <hr />
          </div>
        </section>
      </div>
    )
  }
}
