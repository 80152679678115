import React, { Component } from 'react'

export default class SendMail extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="sendmail">
          <div className="colorlib-narrow-content">

            <div className="text-center">

              <span className="email"><i className="icon-mail"></i><a href="mailto:alessandro-tamburini@libero.it" rel="noopener noreferrer" target="_blank">alessandro-tamburini@libero.it</a></span>
            </div>




            <hr />
          </div>
        </section>
      </div>
    )
  }
}
