import React, { Component } from 'react'

export default class OnlineWorksBrennero extends Component {
  render() {
    return (
 <div class="modal" id="onlineworksbrennero" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-full" role="document">
        <div class="modal-content">
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
            <div class="modal-header">
                <h5 class="modal-title">Brennero - Alessandro Tamburini</h5>
            </div>
            <div class="modal-body p-4" id="result">
            Era un treno tedesco, moderno e confortevole come erano sempre i loro, o gli sembravano in confronto alle tradotte delle linee interne italiane. E lui lo apprezzava doppiamente perché si sentiva molto stanco. La trasferta di lavoro che lo portava due volte l'anno a Stoccarda era sempre un test del suo stato di forma, e stavolta ne usciva decisamente provato. Colpa anche di un contrattempo che gli aveva fatto saltare l’orario previsto, obbligandolo a un più oneroso viaggio notturno. Era buio già da diverse ore, e ancora molte ne mancavano per raggiungere la meta. <br/>
Bisogna poi dire che era dicembre avanzato e che le montagne austriache, ombre su ombra nel riquadro del finestrino, si intuivano ammantate di un freddo pungente. Il treno invece era anche troppo riscaldato e lui sudava, nonostante si fosse messo in maniche di camicia e liberato della cravatta. Per un istante si vide come dall'alto, dentro l'involucro caldo e illuminato che perforava la notte gelida. Desiderò accendere una sigaretta e si pentì di aver scelto uno scompartimento per non fumatori, mosso dal ciclico proposito di limitare se non di abbandonare quel vizio. <br/> 
Il convoglio, che aveva rallentato per affrontare le rampe e le strettoie del valico, procedeva ora fra pareti di roccia incombenti. E come giunse al primo respiro della valle, uno slargo coronato di luci che si aprì improvviso al termine di una galleria, si arrestò, con un paio di contrazioni che sembrarono rivelarne la lunghezza. <br/>
Il pieghevole fornito dalle ferrovie tedesche indicava tutte le stazioni del percorso, da Dusseldorf a Milano e lui vide che alla fermata del Brennero era prevista una sosta di dieci minuti. Allora senza esitare raccolse la giacca, la tastò per sincerarsi che contenesse sigarette e cerini e con un gesto che risultò decisamente troppo energico fece scorrere la porta dello scompartimento. <br/>
Il corridoio era sgombro e attraverso le porte a vetri vide che rari e quieti passeggeri occupavano il vagone. Dallo sportello spalancato entrava un getto di aria viva e ne prese un lungo respiro, finendo di convincersi di aver avuto una buona idea, ma appena a terra lo investì una ventata gelida che lo indusse a infilare svelto la giacca e a rimpiangere il cappotto. Poi si disse che era solo il tempo di una sigaretta, e la accese gustandone avidamente le prime boccate. <br/>
Il treno si era fermato sul terzo binario e si allungava senza che se ne potesse vedere la fine, oltre le pensiline deserte. La stazione era immersa in una quiete assoluta e ne fu invogliato a commettere la piccola infrazione di attraversare i binari senza servirsi del sottopassaggio, come prescriveva il cartello affisso proprio lì di fronte. Lungo la parete si apriva una serie di porte chiuse e buie, il silenzio era tale che si sentì distintamente lo scatto dell'orologio appeso alla pensilina e anche il carretto di legno dai lunghi manici, come il vetusto telefono a muro, parevano oggetti abbandonati da anni. La sola cosa viva era il treno, le cui luci accendevano blandi riflessi sulle colonne lucide. <br/>
Con le mani in tasca e la sigaretta fra le labbra costeggiò l’edificio, accompagnato dall'eco dei propri passi che risuonava sotto la pensilina, e raggiunse un giardinetto che sembrava a sua volta uscito da una cartolina d'epoca, cinto da una magra siepe e con le panchine di cemento intorno a una fontanella, che gettava in aria uno zampillo arcuato e sottile. Fu la sua vista a fargli avvertire uno stimolo improvviso, e la voglia di sgravarsi subito. Esitò ancora un momento volgendo lo sguardo al treno, poi si sbottonò i pantaloni.
Era piacevole respirare l'aria pura dell'inverno, e insieme alla pipì che fumava nella penombra gli sembrava di liberarsi anche della stanchezza che gli pesava sulle membra e sui pensieri. Poi si perse per qualche istante nell’immobilità e nel silenzio che aveva attorno, quasi irreali per una stazione. 
Lo riscosse un sordo frastuono che lo fece voltare di colpo verso i binari. Ma il suo intercity era sempre immobile al terzo, ce ne doveva essere un altro in arrivo. Comunque si avviò svelto, fissando il treno quasi che potesse tenerlo ancorato con lo sguardo, con una fitta di ansia che pure giudicò immotivata, poiché pochi secondi sarebbero bastati per raggiungerlo. Ma intanto il frastuono ingigantiva e un merci gli scivolò a fianco, si trascinò ancora per un tratto e poi si arrestò con uno stridio di freni, precludendogli il passaggio attraverso i binari. <br/>
Capì che la propria leggerezza rischiava di costargli cara e si lanciò in direzione del sottopassaggio, scese a precipizio le scale, ma mentre correva nell'angusto corridoio sotterraneo lo raggiunse il fischio temuto, e risalendo a balzi in superficie vide gli sportelli già chiusi. Con un goffo balzo si gettò contro il più vicino, ma già sapeva trattarsi di un gesto vano. Infatti il convoglio si mosse, chiuso e impenetrabile, e lui dovette staccarsi dal predellino. Poi non gli restò che un accenno di corsa con il braccio alzato, che pure avvertiva patetico, mentre il treno acquistava velocità e un vagone alla volta spariva inghiottito dal buio, con le lucine rosse a occhieggiare beffarde sulla coda . <br/>
Un istante dopo anche il merci si mosse, quasi che la sua comparsa rispondesse a un disegno preordinato a suo danno. Seguì mestamente con lo sguardo il lungo funerale dei vagoni, finché un silenzio senza appello calò sulla stazione e insieme a un lungo brivido di freddo gli si mosse dentro la calca dei pensieri fino ad allora trattenuti. Dovette così passare mentalmente in rassegna il contenuto della valigia rimasta sul treno, e le più immediate conseguenze del mancato arrivo all'ora prevista. <br/>
Lo aspettava un importante appuntamento di lavoro, nella tarda mattinata del giorno seguente, l'incontro con un cliente preparato da tempo insieme a un collega, e c'era seriamente da dubitare che avrebbe potuto arrivare in tempo. Avrebbe dovuto svegliare sua moglie e i bambini per avvertirli, e non avrebbe potuto accompagnare il più piccolo a scuola come previsto. Per giunta aveva lasciato sul treno anche il cellulare. Nell’immediato era quello, insieme al cappotto, la mancanza più dolorosa. <br/>
Fra le colonne del primo binario fece capolino un berretto da ferroviere, ma appena lui ebbe raggiunto il punto in cui l'aveva intravisto si trovò davanti solo la fila di porte buie e silenziose. Poi dalla più vicina, alla quale stava per bussare, uscì un poliziotto nell'atto di calzare il cappello della divisa. Una luce calda proveniva dall'interno, resa più intima dalla musica di una radio.
Si schiarì la voce e stringendosi nella giacca, per il freddo e per rimarcare la gravità della propria situazione, spiegò cosa gli era successo, domandò a che ora sarebbe passato il prossimo treno, si informò riguardo al recupero del proprio bagaglio e alla disponibilità di un telefono. 
Il poliziotto continuava ad assestarsi il cappello e quando lui ebbe finito gli rispose con un tono neutro che lo raggelò ulteriormente. Il primo treno utile sarebbe passato alle sei e trenta del mattino, l'unico telefono si trovava nel bar che a quell'ora era chiuso, per il bagaglio avrebbe dovuto tornare il giorno dopo a sporgere denuncia. Quindi col gesto di chi fa una grossa concessione gli aprì la porta di una sala d'aspetto squallida e disadorna, da cui usciva un soffio di aria che sembrava ancora più gelida di quella dell'esterno. 
Proprio in quel momento le lancette dell'orologio si sovrapponevano sulle dodici e fu preso da uno di quei moti di rabbia impotente che a volte sanno suscitare gli uomini in divisa. <br/>
"Io devo telefonare, e subito" protestò. "E non posso starmene qua fino all'alba in queste condizioni!" <br/>
"Non lontano da qui c'è un caffè che resta aperto anche la sera, ma non so a quest'ora..." lo informò l'altro senza scomporsi. <br/> <br/>


Ogni piccolo centro del nord passata la mezzanotte sembra una città fantasma, ma quello li superava tutti. Segnato da rari lumi di lampioni ondeggianti sui fili, il paesaggio che lui si trovò davanti subito fuori dalla stazione comprendeva da un lato un piazzale recintato, con alcuni vecchi locomotori abbandonati in una ragnatela di binari, e dall'altro, costruito a ridosso della montagna, un abitato simile ai quartieri dormitorio sorti alla periferia delle grandi città: edifici uguali e squadrati, di quattro o cinque piani, file di balconi con le ringhiere di ferro, e non una tenda, non un fregio o una pianta ad animare la geometria grigia e squadrata delle facciate. Il tutto immerso in un silenzio che si sarebbe detto definitivo. <br/>
Superò il primo isolato e a metà del secondo vide l’insegna del bar, spenta come temeva e si aspettava, e poi la serranda abbassata. Accese una sigaretta, perché altro in tasca non aveva, e succhiò avidamente il conforto di quel briciolo di calore; quindi sputando fiotti di fumo e vapore cominciò a saltellare sbattendo le braccia. Se qualcuno lo avesse visto avrebbe potuto prenderlo per matto, ma tanto non c’era anima viva e lui doveva scuotersi di dosso quel torpore che gli impediva di pensare; doveva ritrovare un minimo di lucidità per affrontare o almeno per sopportare la situazione. Il freddo aveva ormai annullato la protezione della giacca, la prospettiva della sala d’aspetto era desolante e quale reale alternativa era lecito aspettarsi in quella landa desolata? Eppure almeno un telefono doveva trovarlo a ogni costo. <br/>
Gettò la cicca e si mosse con un passo fra la marcia serrata e la corsa. Non erano mica le quattro del mattino, in fondo. Era da poco passata la mezzanotte e avrebbe dovuto pur esserci qualcuno ancora sveglio, anche in quello strano paese. Infatti vide quasi subito una luce in una casa d’angolo, a una finestra del primo piano. <br/>
La fiamma dell’accendino guizzò negli inserti di vetro della porta d’ingresso, poi illuminò una fila di campanelli dalle diciture scrostate o illeggibili, tranne una. Controllò di nuovo l’ora, mentre cercava il tono convincente e rassicurante che gli sembrava necessario per esporre in estrema sintesi la propria richiesta di telefonare. <br/>
Suonò, e quasi subito gli rispose la voce pronta e vivace, per nulla allarmata, di una donna che certamente non era stata svegliata dal campanello. Ma lui era troppo stanco e teso, e suo malgrado proruppe in una raffica di parole concitate, tali da destare -o così temette-  la peggiore impressione possibile. Perciò rimase stupito quando come risposta arrivò solo il tiro che gli apriva la porta d’ingresso. <br/>
Salì le scale, nel verde pallido diffuso dalle pareti e dai corrimano, e a metà della seconda rampa vide la porta che si schiudeva, poi il volto di una donna molto più anziana di quanto la voce non lasciasse supporre. Si sforzò di sorriderle, mentre ribadiva quanto aveva detto al citofono, ma la frase rimase a metà perché arrivato al piano lei fece un mezzo passo avanti e lo abbracciò, lasciandolo basito. Era piccola, gli arrivava appena alla spalla, e fu un abbraccio breve e forte, dal basso verso l'alto. Poi la vecchia abbassò le braccia ma non smise di toccarlo, al fianco e sulla mano, e da questo, oltreché dalla sua espressione contenta ma distante, lui capì che non vedeva bene. <br/>
"Sei vestito troppo poco Vittorio, e mi sembri anche un po' dimagrito. E’ questo andare sempre in giro che ti consuma" gli disse. Poi gli annusò la giacca e soggiunse "Hai fumato! Prometti sempre ma poi…", e le ultime parole gli destarono una sensazione fortissima, come se le avesse ascoltate dalla voce della propria madre, che era scomparsa da più di dieci anni. Tardò a rispondere e non gli sembrò abbastanza persuasivo il tono con cui disse che lui non era Vittorio, era solo uno di passaggio che aveva perso il treno e doveva fare una telefonata. <br/>
"Avrai fame," disse infatti lei come non lo avesse sentito "ma lo sai che tengo sempre in caldo qualcosa per te", e gli fece strada in un'anticamera semibuia, poi in un soggiorno che aveva al centro una poltrona a righe, nel fascio di luce di una lampada a stelo. Si sarebbe detto che si fosse appena alzata da lì, ma non vi era intorno segno di alcuna occupazione, né un giornale né un lavoro a maglia, e il vecchio televisore con sopra un centrino e una pianta grassa dava l'idea di non essere in funzione da molto tempo. <br/>
Dal soggiorno la vecchia passò subito nell’attigua cucina, stretta e lunga e tutta fasciata di pensili di formica mentre lui, fermo sulla porta, venne raggiunto dal profumo del cibo; sembrava di carne stufata e divenne subito più forte quando lei sollevò il coperchio di una pentola e ne mescolò il contenuto. La tavola era apparecchiata per una persona, col tovagliolo infilato nell'anello e la bottiglia di vino a metà. <br/>
Intanto la vecchia aveva dato il via a quello che sembrava un suo preciso rituale di gesti intorno ai fornelli. Si muoveva con passi brevi e silenziosi, perché ai piedi calzava  delle pantofole chiuse di lana cotta. Aveva un corpo minuto e magro, su cui cadevano dritti il golfino e la gonna dello stesso colore. Nel volto liscio e colorito spiccava il naso adunco mentre le labbra erano quasi invisibili, al centro di una raggiera di piccole rughe. <br/>
“Vai a lavarti le mani, c’è l’asciugamano pulito" gli disse voltandosi, con quegli occhi spenti e incapaci di raggiungere il loro obiettivo. E come se avesse dovuto sollevare un grosso peso lui fece appello a tutta la propria forza di volontà per replicare; ma fu di nuovo sconfitto e tacque, perciò non gli restò che tornare verso l'anticamera, dove aveva intravisto delle porte chiuse.
“Questa è fuori come un balcone” mormorò guardandosi allo specchio del bagno, un vano minuscolo e senza finestre dove regnava un ordine perfetto, come nel resto della casa, per quanto aveva potuto vedere. <br/>
“E proprio qui dovevo andare a capitare?” disse a voce alta, così da rendere ancora più evidente l'inadeguatezza delle espressioni che gli salivano alle labbra, con cui cercava invano di ridurre la portata di quanto gli stava accadendo. E mentre si lavava con piacere le mani con l'acqua calda, poi bollente perché il vecchio rubinetto era privo di miscelatore, congetturava su come cavarsi da quell'impiccio senza offendere o ferire la vecchia, e possibilmente dopo aver usato il suo telefono. Se mai si sarebbe in seguito informato se il suo stato di salute mentale fosse noto a qualcuno, avrebbe contattato qualche ufficio del luogo interessando un funzionario del Comune, o un assistente sociale. Si sentiva abbastanza prostrato da non chiedersi nulla di più, almeno per il momento. <br/>
 "Il telefono?" disse la vecchia, e staccò la cornetta dall'apparecchio a muro che era appeso accanto alla porta del soggiorno. Se la portò all'orecchio e poi gliela tese scuotendo la testa. "Non va" disse come chiamandolo a testimone di un fatto senza ragione. <br/>
"Be', io devo trovarne uno subito" replicò secco lui. "Lei non ha un vicino di casa a cui potremmo chiedere... E' importante".
"Un vicino di casa..." ripeté lei, come se non riuscisse a focalizzare bene quelle parole. <br/>
Lui pensò che avrebbe voluto poter raccogliere qualcosa, un soprabito o una borsa, per dare un po’ di slancio all’atto di andarsene. Poté solo stringersi per un momento nelle spalle, come presentendo il freddo che lo aspettava fuori. 
“Non voglio darle altro disturbo” disse, e mosse svelto verso la porta per non darle il tempo di replicare, ma non era arrivato all’ingresso che lei col suo passo felpato lo raggiunse. Si muoveva sempre portando le mani in avanti, tese verso i punti d’appoggio di uno spazio che doveva esserle noto fin nei minimi dettagli. <br/>
“Dove vuoi andare Vittorio? Sei arrivato adesso…” gli disse posandogli la mano sul braccio con quel suo tocco da bambina. Gli ricordò precisamente quello della propria figlia, quando certe mattine lo seguiva fin sulla porta e cercava di impedirgli o almeno di ritardargli l’uscita da casa.
“Lei si sbaglia” disse. “Io non sono la persona che stava aspettando”, e fu subito atterrito dal peso e dal possibile effetto delle proprie parole. 
“Perché ti arrabbi? Ho detto qualcosa di male?” disse invece lei, come portando avanti un suo dialogo interiore, del tutto estraneo alle parole dell'interlocutore.   <br/> 
“ Scusami sai, è a stare tanto qui da sola... Sei stanco. Mangia qualcosa” soggiunse, e lui provò ancora a riscuotersi.
“Non è proprio possibile” disse. 
“Perché no?” disse lei, e intanto senza darlo a parere lo guidava di nuovo verso il soggiorno, come avesse a che fare con un bambino riottoso.
Perché lei è pazza e io ho già abbastanza guai!, avrebbe voluto dirle, ma non prese nemmeno in considerazione l’idea di farlo e tacque. Arrivato davanti alla porta della cucina rivide la tavola apparecchiata, il piatto in cui era già stato servito il cibo, e seppe che non avrebbe potuto andarsene senza averlo mangiato. A questa constatazione fece seguito una contrazione alla bocca dello stomaco, se di nausea o di appetito non avrebbe saputo dire.
Sedette e spiegò il tovagliolo, gettò un rapido sguardo nel piatto dove tre grosse polpette al sugo fronteggiavano una mezzaluna di patate stufate. La vecchia stava in piedi sull’altro lato della stanza; la invitò a sedere, ma lei non si mosse. Allora si decise a portare alle bocca un assaggio, e un momento dopo trangugiava a grossi bocconi quel cibo che gli sembrava squisito.
“Mastica bene, è la prima regola per una buona digestione” lo rimbrottò la vecchia, e lui obbedì subito, si pulì le labbra col tovagliolo che odorava di canapa stagionata,  poi versò e bevve il vino, che produsse a sua volta un percepibile effetto benefico sulle pareti dello stomaco.
“A che ora riprendi servizio?” domandò la vecchia, e a lui non parve vero di poter rispondere a senso a quelle parole.
“Il mio treno parte alle sei e trenta”
“Puoi dormire un po’”.
“Adesso è proprio meglio che vada” disse lui alzandosi. “Grazie, era tutto molto buono”.
“Lo so che quando hai poco tempo non ti va di metterti a letto” replicò lei pronta, e lo precedette nel soggiorno. “Mettiti sulla poltrona, io non ti darò fastidio. A quest’ora non dormo, ma se non ti dispiace starò qui”, e dopo aver spento la parte alta della lampada si accostò a un sofà dall'aspetto poco accogliente, con una serie di grandi cuscini a fiori messi come spalliera.
“Ti chiamo io quando è ora” gli disse, e lui si lasciò cadere nella poltrona, gustando i vantaggi dell’arrendevolezza a cui aveva deciso di consegnarsi. Senza muovere il capo osservò lo scarno mobilio del soggiorno e si soffermò sul piano della credenza, dove fra una sveglia meccanica e un apparecchio radio con i fili avvolti intorno all'antenna stava la fotografia di un giovane uomo coi baffi, in divisa da ferroviere, incorniciata in un portaritratti d’argento; poi tornò a rivolgersi alla vecchia. Si era seduta sul sofà, con le mani raccolte in grembo e quel suo sguardo sfuocato, come di una sentinella di guardia davanti a un'oscurità insondabile. Lui abbassò di un altro grado la propria vigilanza. 
“Mi riposo un momento e poi vado” disse fra sé, e lasciò cadere le palpebre. <br/> <br/>


Spalancò gli occhi e fu subito presente a se stesso, come se li avesse chiusi solo per qualche istante, ma aveva dormito a lungo. Lo capì dal torpore delle membra, e dalla diversa densità del silenzio che aveva intorno, nell’uguale luce giallastra che la lampada proiettava sul pavimento.
La vecchia dormiva, riversa su un fianco sul bordo del sofà,  proprio come una sentinella che avesse ceduto a un sonno improvviso. Pareva ancora più minuscola e il suo viso era atteggiato a un’espressione serena, quasi beata.
Con fatica si alzò, si sgranchì, andò in cucina e bevve lunghi sorsi d’acqua dal rubinetto del lavello; quindi tornò in soggiorno, attento a evitare anche il più piccolo rumore per non destare la vecchia. <br/>
La sveglia sulla credenza segnava le cinque e mezzo e il suo tic tac si avvertiva distintamente, come il cuore della casa addormentata. Mosse ancora qualche passo qua e là, senza scopo, finché lo sguardo gli cadde sul portaritratti e subito vi si accostò per guardare meglio quella foto. Gli fu facile estrarla, fissata com'era da una semplice linguetta; si trattava di un santino mortuario, come aveva intuito di colpo qualche istante prima. Lesse la breve dicitura e la confrontò più volte con l'uomo del ritratto, che si chiamava Vittorio ed era morto il sedici agosto di quattro anni prima. 
Tornò a sedersi sulla poltrona e il cigolio delle molle bastò a svegliare la vecchia, che si tirò subito a sedere. 
“Ho dormito!" disse con un tono di quieto stupore, e lui senza sforzo le sorrise. 
"Di notte solo quando ci sei tu riesco a prendere sonno” soggiunse lei con un sorriso di benevolenza rivolto a se stessa., e si voltò verso la finestra. Così vide anche lui che ne trapelava un chiarore ferrigno, primo annuncio dell’alba. Allora si alzò e lei fece subito lo stesso. 
“Metto su il caffè” gli disse dirigendosi verso la cucina, e al vago gesto di diniego di lui replicò “Ho già preparato la Moka, è subito pronto”. 
Lui annuì. Un caffè era ciò che desiderava di più al mondo, e senza chiedere il permesso andò in bagno, si sciacquò il viso e si pettinò. Si sentiva pesto, ma il proprio viso riflesso nello specchio gli restituì un'immagine confortante di sé, e il pensiero delle telefonate che doveva fare non gli risultò più così affannoso. Sua moglie e i piccoli stavano ancora dormendo, come il cliente dell’appuntamento, e avrebbe fatto di certo in tempo ad avvertirli. Quel che gli era capitato nelle ultime ore lo aveva affrancato da ansie o doveri di sorta. <br/>
"C’è qualcuno che la accompagna quando deve uscire?” si informò, mentre giravano con lo stesso ritmo lento i cucchiaini nelle chicchere. 
“Non esco mai io” disse la vecchia. 
“Neanche per fare la spesa, o per ritirare la pensione?”
“La spesa me la porta quel commesso gentile, pane e latte ogni giorno e il resto al martedì” rispose lei “e la pensione ..”
Ridacchiò e anche il suo sguardo opaco ebbe un sussulto.
“… me la porta sempre lui, anche se non sarebbe legale. Ma l’impiegata delle poste è sua cugina e ci fa il favore”.  
Aveva un viso disteso e sembrava molto meno protesa verso di lui di quanto non fosse la sera avanti, quasi che la sua presenza fosse valsa a nutrirla e a pacificarla, a farla tornare in sé almeno abbastanza da sorbire di gusto il suo caffè. Per un momento gli sembrò che lo considerasse per quello che era,  uno sconosciuto capitatole in casa nottetempo, con cui era piacevole scambiare qualche parola mentre cominciavano insieme una nuova giornata.
Infatti quando lui guardò l'orologio si alzò con un movimento tranquillo.
“Ti occorre niente?” gli domandò. Lui fece segno di no e lei disse “Allora ti  accompagno”.
Davanti alla porta gli prese il capo fra le mani e lo tirò alla propria altezza per dargli un bacio sulla guancia. Lui percepì chiaramente il contatto delle sue labbra dure e asciutte, e la commozione che trapelava dalle mani.
Gli aprì la porta, gli accese la luce delle scale.
“La prossima volta ti faccio trovare la pasta al sugo” gli disse, e lui scese svelto i primi gradini, ma si fermò alla fine della rampa e riuscì a dire “Grazie di tutto”, mentre la vecchia lo salutava con la mano alzata, come per una benedizione. <br/> <br/>

Uscì in strada guardando il cielo, che sbiadiva nella luce perlacea dell'aurora, mentre qualche sacca di oscurità resisteva acquattata alle falde dei monti. Il freddo si era fatto ancora più intenso e quasi impediva al fiato di uscire, ma il cibo e il sonno avevano costituito un presidio che glielo rendeva meno temibile. 
Camminava al centro della strada deserta. Dopo un po’ si ficcò le mani in tasca e questo lo mise di buon umore. Non c’era ancora nessuno in giro ma qualche finestra era illuminata, l’abitato si stava liberando della veste fantasmatica della sera prima. E rivedendo l'insegna del bar, i lampioni ancora accesi e da lontano la sagoma squadrata della stazione, si sentì circondato da un paesaggio familiare o che tale gli sembrava, come in forza di una memoria futura; e seppe che sarebbe tornato. 
Accelerò il passo quando il fischio di un treno risuonò e riecheggiò nel breve spazio chiuso fra i monti.      
                   
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
        </div>
    </div>
</div>


    )
  }
}
