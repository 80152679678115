import React, { Component } from 'react'

export default class WorksPrimoMondo extends Component {
    render() {
        return (
            <div class="modal" id="worksprimomondo" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-full" role="document">
                    <div class="modal-content">
                    <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
                        <div class="modal-header">
                            <h5 class="modal-title">  <strong>&quot;Nel nostro primo
        mondo&quot;</strong>(racconti): &nbsp;<em>Marsilio Editori, Venezia, 1990 .</em><br /><br />
                                Selezionato dalla Fondazione Bellonci, Roma, per l'Invito
                                alla lettura 1991. Vincitore del Premio
        &quot;Settembrini&quot; 1991.  </h5>
                           
                        </div>
                        <div class="modal-body p-4" id="result">



                            <div align="left">






                                <br /><br /><br />



                                <br />

                                <strong>ne hanno scritto:</strong> <br /><br />

                                Piero Cigada, Il Sole 24 ore, 29.7.90<br />
                                Generoso Picone, Il Mattino di Napoli, 24.7.90<br />
                                Claudio Marabini, Il Resto del Carlino, 15.5.91<br />
                                Giampaolo Rugarli, L'Espresso, 12.8.90<br />
                                Attilio Lolini, L'Unità, 24.1.<br />r>
Fulvio Panzeri, Avvenire, 3.5.91<br />
                                Filippo La Porta, Il Manifesto, 5.10.90<br />
                                Bruno Kandler, Panorama, 9.12.90<br />
                                Giampaolo Visetti, L'Adige, 8.6.90<br />
                                Carlo Martinelli, L'Alto Adige, 12.7.90<br />
                                Claudio Toscani, La Provincia di Cremona, 31.7.90<br />
                                Elisabetta Marchetti, Nuova Vicenza, 13.10.90<br />
                                Rosa Maria Salvi, L'Eco di Bergamo, 14.9.90<br />
                                Alessandro Cogolo, Il Gazzettino di Venezia, 30.1.92<br />
                                Renzo Oberti, La Provincia di Como, 13.8.90<br />
                                Daniela Marcheschi, Stazione di Posta, luglio 90<br />
                                Massimo Tarantino, Ciao 2001, 2.10.90<br />
                                Idolina Landolfi, Molloy, ottobre 91<br />
                                Francesco Roat, QuestoTrentino, settembre 90<br />
                                Fulvio Panzeri, L'Esagono, 2.7.90<br />
                                Intervista, La Gazzetta di Ancona, 12.5.90<br />
                                Luigi Fontanella, America Oggi, 2.6.91
    
</div>



                            <div align="left">
                                <strong> Selezione Stampa</strong>
                                <table border="0" align="center" cellspacing="7">
                                    <tr>

                                        <td align="center" width="100">Risvolto di copertina di Pier Vittorio Tondelli.
        (137k)</td><td><a href="rec\primomondo-tondelli.zip"><img src="./img/rec/primomondo-tondelli_small.jpg" alt="scarica in formato zip"

                                            width="198" height="192"></img></a>

                                        </td>
                                    </tr><tr>
                                        <td align="center" width="100">Il Resto Del Carlino 1991
                                        &quot;Piccoli Uomini Crescono Negli Anni Sessanta&quot;
        di Claudio Marabini. (165k)</td><td><a href="rec\primomondo-piccoli.zip"><img
                                            src="./img/rec/primomondo-piccoli_small.jpg" alt="scarica in formato zip" width="163"
                                            height="260"></img></a>
                                        </td>
                                    </tr><tr>

                                        <td align="center" width="100">Il Sole 24 Ore 29/7/1990 &quot;C'e Un
        Cerchio Alle Prese Col Fato&quot; di Piero Gigada. (149k)</td><td><a href="rec\primomondo-un cerchio.zip"><img
                                            src="./img/rec/primomondo-un%20cerchio_small.jpg" alt="scarica in formato zip" width="232"
                                            height="184"></img></a>
                                        </td>
                                    </tr><tr>
                                        <td align="center" width="100">Il Mattino di Napoli 24/7/1990
                                                &quot;Quanto è Difficile Aspettare il Nuovo&quot; di
        Generoso Picone. (111k)</td><td><a href="rec\primomondo-quanto.zip"><img
                                            src="./img/rec/primomondo-quanto_small.jpg" alt="scarica in formato zip" width="169"
                                            height="296"></img></a>
                                        </td>
                                    </tr>
                                </table>
                            </div>





                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
                        </div>
                    </div>
                </div>
            </div >


        )
    }
}
